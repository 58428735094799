<template>
  <b-card-code>
    <div v-if="$can('read','Subject')" class="custom-search d-flex">
      <router-link :to="{ name: 'subject' }">
        <b-button variant="outline-primary"> {{ $t('Back To List') }} </b-button>
      </router-link>
    </div>
    <br />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="subject_add">
        <b-row>
          <b-col md="4">
            <b-card-text>
              <span>Select Class </span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="class"
              rules="required"
            >
              <select
                name="classes_id"
                v-model="classes_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
              >
                <option :value="null">Select One</option>
                <option
                  v-for="(cls, key) in classes"
                  :value="cls.id"
                  :key="key"
                >
                  {{ cls.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- <b-col md="4">
            <b-card-text>
              <span>Select Version</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="version"
              rules="required"
            >
              <select
                name="version_id"
                v-model="version_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
              >
                <option :value="null">Select One</option>
                <option
                  v-for="(version, key) in versions"
                  :value="version.id"
                  :key="key"
                >
                  {{ version.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->
          <b-col md="4">
            <b-card-text>
              <span>Select Group</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="student group"
              rules="required"
            >
              <select
                name="student_group_id"
                v-model="student_group_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
              >
                <option :value="null">Select One</option>
                <option
                  v-for="(group, key) in student_groups"
                  :value="group.id"
                  :key="key"
                >
                  {{ group.name }}
                </option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Name </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="subject name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Subject name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>Short Name </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Short name"
                rules="required">
                <select
                  name="short_name"
                  v-model="short_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option value="HMD1">HMD1</option>
                  <option value="HMD2">HMD2</option>

                  <option value="AC1">AC1</option>
                   <option value="AC2">AC2</option>

                  <option value="ACC1">ACC1</option>
                   <option value="ACC2">ACC2</option>

                  <option value="AGRI">AGRI</option>
                  <option value="AGRI1">AGRI1</option>
                  <option value="AGRI2">AGRI2</option>

                  <option value="BANG1">BANG1</option>
                   <option value="BANG2">BANG2</option>

                  <option value="BENT1">BENT1</option>
                   <option value="BENT2">BENT2</option>

                  <option value="BGS1">BGS1</option>
                   <option value="BGS2">BGS2</option>

                  <option value="BIO1">BIO1</option>
                  <option value="BIO2">BIO2</option>

                  <option value="CE1">CE1</option>
                   <option value="CE2">CE2</option>

                  <option value="CHE1">CHE1</option>
                   <option value="CHE2">CHE2</option>

                  <option value="HR/CRS1">HR/CRS1</option>
                  <option value="HR/CRS2">HR/CRS2</option>

                  <option value="ECON1">ECON1</option>
                  <option value="ECON2">ECON2</option>

                  <option value="ENG1">ENG1</option>
                  <option value="ENG2">ENG2</option>

                  <option value="FIN1">FIN1</option>
                   <option value="FIN2">FIN2</option>

                  <option value="GM1">GM1</option>
                   <option value="GM2">GM2</option>

                  <option value="HM1">HM1</option>
                  <option value="HM2">HM2</option>
                  
                  <option value="HSCI1">HSCI1</option>
                  <option value="HSCI2">HSCI2</option>

                  <option value="ICT1">ICT1</option>
                  <option value="ICT2">ICT2</option>

                  <option value="IME1">IME1</option>
                  <option value="IME2">IME2</option>

                  <option value="MATH">MATH</option>
                  <option value="MATH1">MATH1</option>
                  <option value="MATH2">MATH2</option>

                  <option value="MGMT1">MGMT1</option>
                  <option value="MGMT2">MGMT2</option>

                  <option value="PE1">PE1</option>
                  <option value="PE2">PE2</option>

                  <option value="PHY">PHY</option>
                  <option value="PHY1">PHY1</option>
                  <option value="PHY2">PHY2</option>
                   
                  <option value="SCI">SCI</option>
                  <option value="SCI1">SCI1</option>
                  <option value="SCI2">SCI2</option>

                  <option value="STAT1">STAT1</option>
                  <option value="STAT2">STAT2</option>

                  <option value="WL1">WL1</option>
                  <option value="WL2">WL2</option>

                  <option value="SELFSTD1">SELFSTD1</option>
                  <option value="SELFSTD2">SELFSTD2</option>

                  <option value="TMP1">TMP1</option>
                  <option value="TMP2">TMP2</option>

                  <option value="FMD1">FMD1</option>
                  <option value="FMD2">FMD2</option>

                  <option value="LIBW1">LIBW1</option>
                  <option value="LIBW2">LIBW2</option>

                  <option value="MOETH1">MOETH1</option>
                  <option value="MOETH2">MOETH2</option>

                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>Subject Code </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="subject code"
                rules="required|integer"
              >
                <b-form-input
                  v-model="code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Subject code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Has Part?</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="subject has part selection"
              rules="required"
            >
              <b-form-radio-group
                v-model="has_part"
                :options="options"
                stacked
                name="has_part"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Is Optional?</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="subject is optional or not?"
              rules="required"
            >
              <b-form-radio-group
                v-model="is_optional"
                :options="options"
                stacked
                name="is_optional"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Is Mark Effective?</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="subject has part selection"
              rules="required"
            >
              <b-form-radio-group
                v-model="has_mark_effect"
                :options="options"
                stacked
                name="has_mark_effect"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Is Grade Subject ?</span>
            </b-card-text>
            <validation-provider
              #default="{ errors }"
              name="subject has part selection"
              rules="required"
            >
              <b-form-radio-group
                v-model="is_grade_subject"
                :options="options"
                stacked
                name="is_grade_subject"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <template v-if="has_part">
            <b-col md="4">
              <b-card-text>
                <span>Other Subject Name </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="other subject name"
                  rules="required"
                >
                  <b-form-input
                    v-model="other_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Subject name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
            <b-card-text>
              <span>Other Subject Short Name </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Short name"
                rules="required">
                <select
                  name="other_subject_short_name"
                  v-model="other_short_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  class="form-control">
                  <option :value="null">Select One</option>
                  <option value="HMD1">HMD1</option>
                  <option value="HMD2">HMD2</option>

                  <option value="AC1">AC1</option>
                   <option value="AC2">AC2</option>

                  <option value="ACC1">ACC1</option>
                   <option value="ACC2">ACC2</option>

                  <option value="AGRI1">AGRI1</option>
                  <option value="AGRI2">AGRI2</option>

                  <option value="BANG1">BANG1</option>
                   <option value="BANG2">BANG2</option>

                  <option value="BENT1">BENT1</option>
                   <option value="BENT2">BENT2</option>

                  <option value="BGS1">BGS1</option>
                   <option value="BGS2">BGS2</option>

                  <option value="BIO1">BIO1</option>
                  <option value="BIO2">BIO2</option>

                  <option value="CE1">CE1</option>
                   <option value="CE2">CE2</option>

                  <option value="CHE1">CHE1</option>
                   <option value="CHE2">CHE2</option>

                  <option value="HR/CRS1">HR/CRS1</option>
                  <option value="HR/CRS2">HR/CRS2</option>

                  <option value="ECON1">ECON1</option>
                  <option value="ECON2">ECON2</option>

                  <option value="ENG1">ENG1</option>
                  <option value="ENG2">ENG2</option>

                  <option value="FIN1">FIN1</option>
                   <option value="FIN2">FIN2</option>

                  <option value="GM1">GM1</option>
                   <option value="GM2">GM2</option>

                  <option value="HM1">HM1</option>
                  <option value="HM2">HM2</option>
                  
                  <option value="HSCI1">HSCI1</option>
                  <option value="HSCI2">HSCI2</option>

                  <option value="ICT1">ICT1</option>
                  <option value="ICT2">ICT2</option>

                  <option value="IME1">IME1</option>
                  <option value="IME2">IME2</option>

                  <option value="MATH">MATH</option>
                  <option value="MATH1">MATH1</option>
                  <option value="MATH2">MATH2</option>

                  <option value="MGMT1">MGMT1</option>
                  <option value="MGMT2">MGMT2</option>

                  <option value="PE1">PE1</option>
                  <option value="PE2">PE2</option>

                  <option value="PHY">PHY</option>
                  <option value="PHY1">PHY1</option>
                  <option value="PHY2">PHY2</option>
                   
                  <option value="SCI">SCI</option>
                  <option value="SCI1">SCI1</option>
                  <option value="SCI2">SCI2</option>

                  <option value="STAT1">STAT1</option>
                  <option value="STAT2">STAT2</option>

                  <option value="WL1">WL1</option>
                  <option value="WL2">WL2</option>

                  <option value="SELFSTD1">SELFSTD1</option>
                  <option value="SELFSTD2">SELFSTD2</option>

                  <option value="TMP1">TMP1</option>
                  <option value="TMP2">TMP2</option>

                  <option value="FMD1">FMD1</option>
                  <option value="FMD2">FMD2</option>

                  <option value="LIBW1">LIBW1</option>
                  <option value="LIBW2">LIBW2</option>

                  <option value="MOETH1">MOETH1</option>
                  <option value="MOETH2">MOETH2</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

            <b-col md="4">
              <b-card-text>
                <span>Other Subject Code </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="other subject code"
                  rules="required|integer"
                >
                  <b-form-input
                    v-model="other_code"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Subject code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </template>
          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormRadioGroup,
} from "bootstrap-vue";
import { required, integer } from "@validations";
import apiCall from "@/libs/axios";

export default {
  name: "subjectAdd",
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormRadioGroup,
  },
  data() {
    return {
      has_part: 0,
      is_optional: 0,
      has_mark_effect: 1,
      is_grade_subject: 0,
      options: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      name: "",
      short_name:null,
      code: "",
      other_name: "",
      other_short_name:null,
      other_code: "",
      required,
      integer,
      classes: [],
      versions: [],
      student_groups: [],
      student_group_id: null,
     // version_id: null,
      classes_id: null,
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = new FormData();
          data.append("classes_id", this.classes_id);
          //data.append("version_id", this.version_id);
          data.append("student_group_id", this.student_group_id);
          data.append("name", this.name);
          data.append("short_name", this.short_name);
          data.append("code", this.code);
          data.append("has_part", this.has_part);
          data.append("is_optional", this.is_optional);
          data.append("has_mark_effect", this.has_mark_effect);
          data.append("is_grade_subject", this.is_grade_subject);
          if (this.has_part == 1) {
            data.append("other_name", this.other_name);
            data.append("other_short_name", this.other_short_name);
            data.append("other_code", this.other_code);
          }
          apiCall
            .post("/subject/store", data)
            .then((response) => {
              if (response.data.status === "success") {
                this.$toaster.success(response.data.message);

                this.name = null;
                this.code = null;
                this.has_part = 0;
                this.other_name = "";
                this.other_code = "";
                //this.$refs.subject_add.reset();
                this.$refs.simpleRules.reset();
              } else {
                this.$toaster.error(response.data.message);
              }
            })
            .catch((error) => {
              this.$toaster.error(error.response.data.message);
            });
        }
      });
    },
    async getAll() {
      await apiCall
        .get("get/active/class/with/v/s/groups")
        .then((response) => {
          this.classes = response.data;
        })
        .catch(() => {
          this.classes = [];
          this.versions = [];
          this.student_groups = [];
        });
    },
  },
  watch: {
    /*watcher for class on change*/
    classes_id() {
      let s_classes = this.classes.find((item) => item.id === this.classes_id);
      //this.version_id = null;
      this.student_group_id = null;
      if (s_classes) {
        this.versions = s_classes.versions ?? [];
        this.student_groups = s_classes.student_groups ?? [];
      } else {
        this.versions = [];
        this.student_groups = [];
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>